export const login = async (email, password) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/login',
      data: {
        email,
        password
      }
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Logged in successfully!');
      window.setTimeout(() => {
        location.assign('/')
      }, 1500);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
}

  export const signup = async (name, email, password, passwordConfirm, profileName) => {
    try {
      const res = await axios({
        method: 'POST',
        url: '/api/v1/users/signup',
        data: {
          name,
          email,
          password,
          passwordConfirm,
          profileName
        }
      });
      if (res.data.status === 'success') {
        showAlert('success', 'Signup successfully!');
        window.setTimeout(() => {
          location.assign('/')
        }, 1500);
      }
    } catch (err) {
      showAlert('error', err.response.data.message);
    }
  }

  export const hideAlert = () => {
    const el = document.querySelector('.alert');
    if (el) el.parentElement.removeChild(el);
  };

  export const logout = async () => {
    try {
      const res = await axios({
        method: 'GET',
        url: '/api/v1/users/logout'
      });

      if (res.data.status === 'success') {
        showAlert('success', 'Logged out successfully!');
        location.reload(true);
        location.assign('/');
      }
    } catch (err) {
      showAlert('error', 'Problem logging out, try again later');
    }
  }
  
// type is 'success' or 'error'
export const showAlert = (type, msg, time = 7) => {
  hideAlert();
  const markup = `<div class="alert alert--${type}">${msg}</div>`;
  document.querySelector('body').insertAdjacentHTML('afterbegin', markup);
  window.setTimeout(hideAlert, time * 1000);
};

document.querySelectorAll('.copy-input-text').forEach(copyLinkContainer => {
  const inputField = copyLinkContainer.querySelector('.input-value-text');
  const copyButton = copyLinkContainer.querySelector('.input-button');

  inputField.addEventListener('focus', () => inputField.select());

  copyButton.addEventListener('click', () => {
    const text = inputField.value;
    
    inputField.select();
    navigator.clipboard.writeText(text);

    inputField.value = 'Copied';
    setTimeout(() => {
      inputField.value = text
    }, 2000);
  });
});